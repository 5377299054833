<script lang="ts">
import { defineComponent } from 'vue'

import VideoHero from '~/vue/_atoms/videos/VideoHero.vue'

export default defineComponent({
    components: {
        'video--hero': VideoHero,
    }
})
</script>