<script setup lang="ts">
import { ref } from 'vue'

import { getClasses } from '~/js/composables/classes'

import { Analytics } from '~/js/interfaces/analytics'


interface Props {
    badge?: number | null,
    content?: string,
    label?: string,
    disabled?: boolean
    ga?: Analytics | null,
    icon?: string,
    iconPosition?: 'before' | 'after',
    type?: 'a' | 'button' | 'div',
    url?: string | null,
    utilities?: string
    utilitiesIcon?: string
}

const props = withDefaults(defineProps<Props>(), {
    badge: null,
    content: '',
    label: '',
    disabled: false,
    iconPosition: 'before',
    size: 'default',
    type: 'button',
    url: null,
    utilities: '',
    utilitiesIcon: '',
})

const classes = ref({
    _default: 'inline-flex items-center group',
    animation: 'transition-colors duraiton-300',
    border: 'border',
    color: 'bg-white',
    font: 'font-primary text-sm md:text-base',
    radius: 'rounded-lg',
    spacing: 'px-4 py-2',
    size: 'w-fit',
})

const classesDisabled = ref({
    border: 'border-gray-400',
    color: 'text-gray-400',
})

const classesEnabled = ref({
    border: 'border-blue-900',
    color: 'text-blue-900',
    focus: 'focus:ring-4 focus:ring-blue-200 focus:outline-none',
    hover: 'hover:text-white hover:bg-blue-900',
})

</script>

<template>
    <component
        :is="disabled ? 'div' : type"
        :class="[
            getClasses(classes), 
            disabled ? getClasses(classesDisabled) : getClasses(classesEnabled),
            iconPosition == 'after' ? 'flex-row-reverse' : 'flex-row',
            utilities
        ]"
        :aria-label="label"
        :tabindex="disabled ? -1 : 0"
        :href="url"
        :data-tag-category="type != 'div' ? ga?.category ?? '' : false"
        :data-tag-action="type != 'div' ? ga?.action ?? '' : false"
        :data-tag-label="type != 'div' ? ga?.label ?? '' : false"
    >
        <span class="order-2 whitespace-nowrap flex items-center">
            <span>{{ content }}</span>
            <span
                v-if="badge"
                class="bg-euromex-red-700 ml-2 text-white font-normal text-xs rounded-full block w-5 h-5 flex items-center justify-center grow-0 shrink-0"
            >
                {{ badge }}
            </span>
        </span>

        <span 
            v-if="icon"
            :class="[
                content.length > 0 ? (iconPosition == 'after' ? 'ml-2' : 'mr-2') : '',
                utilitiesIcon
            ]"
        >
            <i :class="icon"/>
        </span>
    </component>
</template>