export const getTranslation = (translation:any, key:string): string => {
    if (translation && Object.prototype.hasOwnProperty.call(translation, key)) {
        return translation[key]
    }
    return key.charAt(0).toUpperCase() + key.slice(1)
}

export const getGlobalTranslation = (key:string): string => {

    const keys = Object.keys(window.translations).map(key => key.toLowerCase())

    if (keys.indexOf(key.toLowerCase()) > -1) {
        return Object.values(window.translations)[keys.indexOf(key.toLowerCase())]
    }
    
    return key.charAt(0).toUpperCase() + key.slice(1)
}