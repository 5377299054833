<script setup lang="ts">
import { watch, ref } from 'vue'

import { getClasses } from '~/js/composables/classes'
import { getIsMobile } from '~/js/composables/device'
import { getGlobalTranslation } from '~/js/composables/translations'

import { Video } from '~/js/interfaces/media'

import ButtonControls from '~/vue/_atoms/buttons/ButtonControls.vue'

export interface Props {
    video: Video,
    utilities?: string
    reset?: boolean
    rounded?: boolean
    controls?: boolean
    autoplay?: boolean
    mute?: boolean
    loop?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    utilities: '',
    reset: false,
    rounded: true,
    controls: true,
    autoplay: false,
    mute: false,
    loop: false,
})

const classes = ref({
    figure: {
        _default: 'relative overflow-hidden',
        size: 'w-full h-screen',
    },
    video: {
        object: 'object-cover object-center',
        size: 'w-full h-full',
    }
})

const videoEl:any = ref(null)
const muted = ref(props.mute)
const play = ref(props.autoplay)

const toggleMute = () => {
    muted.value = !muted.value
}

const togglePlay = () => {
    if (!videoEl.value) return
    play.value = !play.value

    if (play.value) {
        videoEl.value.play()
    } else {
        videoEl.value.pause()
    }
}

watch(
    () => props.reset,
    () => {
        if (props.reset) {
            if (!videoEl.value) return
            videoEl.value.pause()
            videoEl.value.currentTime = 0
        }
    }
)
</script>

<template>
    <figure 
        :aria-labelledby="video.title"
        :class="[getClasses(classes, 'figure'), utilities]"
    >
        <video 
            ref="videoEl" 
            width="1920"
            height="1080"
            :controls="controls"
            :autoplay="play"
            :muted="muted"
            :loop="loop"
            :class="[getClasses(classes, 'video')]"
            webkit-playsinline playsinline
        >
            <source 
                v-if="getIsMobile()"
                :src="`${video.sd}#t=0.1`" 
                type="video/mp4"
            >
            <source 
                v-if="!getIsMobile()"
                :src="`${video.hd}#t=0.1`" 
                type="video/mp4"
            >
            <slot></slot>
        </video>

        <ButtonControls
            v-if="muted"
            icon="fas fa-volume-xmark"
            type="button"
            :label="getGlobalTranslation('dempen opheffen')"
            utilities="absolute top-2 right-16  text-gray-600 border-none"
            @click="toggleMute()"
        />

        <ButtonControls
            v-if="!muted"
            icon="fas fa-volume-high"
            type="button"
            :label="getGlobalTranslation('dempen')"
            utilities="absolute top-2 right-16  text-gray-600 border-none"
            @click="toggleMute()"
        />

        <ButtonControls
            v-if="play"
            icon="fas fa-pause"
            type="button"
            :label="getGlobalTranslation('pauze')"
            utilities="absolute top-2 right-2  text-gray-600 border-none"
            @click="togglePlay()"
        />

        <ButtonControls
            v-if="!play"
            icon="fas fa-play"
            type="button"
            :label="getGlobalTranslation('spelen')"
            utilities="absolute top-2 right-2 text-gray-600 border-none"
            @click="togglePlay()"
        />
    </figure>
</template>