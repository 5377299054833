import App from '~/vue/apps/Hero.vue'
import { createApp } from 'vue'

// Main app
const main = async () => {
    const app = createApp(App)
    return app.mount('#vue-hero')
}

// Execute async function
main().then(() => {
    console.log()
})